import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate} from 'react-router-dom';
import { collection, query, where, doc, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase';


const blogPostsContent = {
  "future-craft-beer-distribution": {
    title: "The Future of Craft Beer Distribution",
    date: "2024-03-15",
    content: `
      <p>Detailed blog post content goes here...</p>
      <p>You can include HTML formatting in your content.</p>
    `
  },
  // Add more blog posts here
};




export const BlogPost = () => {

  const { slug } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        // Query for the document where slug matches
	const blogRef = collection(db, 'blogs');
	const q = query(blogRef, where('slug', '==', slug));
        const docSnap = await getDocs(q);

        if (docSnap.empty) {
          navigate('/blog');
          return;
        }

	const doc = docSnap.docs[0];
	//console.log('created doc', doc.data());
        setPost({
          id: doc.id,
          ...doc.data(),
        });
      } catch (err) {
        console.error('Error fetching blog post:', err);
        setError('Failed to load blog post. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPost();
  }, [slug, navigate]);

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate();
    return date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };

  if (loading) {
    return (
      <div className="blog-post">
        <div className="container">
          <div className="loading-spinner">
            <div className="spinner-border">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="blog-post">
        <div className="container">
          <div className="error-message" role="alert">
            {error}
          </div>
        </div>
      </div>
    );
  }

  if (!post) {
    return (
      <div className="blog-post">
        <div className="container">
          <div className="not-found">
            <h2>Post not found</h2>
            <Link to="/blog" className="back-link">Back to Blog</Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="blog-post">
      {/* Hero Section with Image */}
      {post.coverImage && (
        <div className="blog-post__hero">
          <div className="blog-post__hero-image">
            <img src={post.coverImage} alt={post.title} />
          </div>
        </div>
      )}

      <div className="container">
        {/* Navigation */}
        <div className="blog-post__nav">
          <Link to="/blog" className="back-link">
            <svg 
              width="20" 
              height="20" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2"
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <path d="M19 12H5M12 19l-7-7 7-7" />
            </svg>
            Back to Blog
          </Link>
        </div>

        {/* Article Content */}
        <article className="blog-post__content">
          <header className="blog-post__header">
            <h1 className="blog-post__title">{post.title}</h1>
            <div className="blog-post__meta">
              <time dateTime={post.timestamp?.toDate().toISOString()}>
                {formatDate(post.timestamp)}
              </time>
            </div>
          </header>

          <div 
            className="blog-post__body"
            dangerouslySetInnerHTML={{ __html: post.content }} 
          />
        </article>

        {/* Footer Navigation */}
        <footer className="blog-post__footer">
          <Link to="/blog" className="back-link">
            ← Back to Blog List
          </Link>
        </footer>
      </div>
    </div>
  );

	/* static
  if (!post) {
    return (
      <div className="blog-container">
        <div className="container mt-5 pt-5">
          <h2>Post not found</h2>
          <Link to="/blog">Back to Blog</Link>
        </div>
      </div>
    );
  }

  return (
    <div className="blog-container">
      <div className="container mt-5 pt-5">
        <Link to="/blog" className="btn btn-link mb-4">← Back to Blog</Link>
        <article>
          <h1>{post.title}</h1>
          <div className="text-muted mb-4">
	    {formatDate(post.timestamp)}
          </div>
          <div className="blog-content" dangerouslySetInnerHTML={{ __html: post.content }} />
        </article>
      </div>
    </div>
  );
  */
};

