import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../../firebase';

const blogPosts = [
  {
    id: 1,
    title: "The Future of Craft Beer Distribution",
    slug: "future-craft-beer-distribution",
    timestamp: "2024-03-15",
    excerpt: "Exploring how technology is reshaping craft beer distribution..."
  },
  // Add more blog posts here
];




export const BlogList = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const blogRef = collection(db, 'blogs');
        const q = query(blogRef, orderBy('timestamp', 'desc'));
        const querySnapshot = await getDocs(q);
        
        const posts = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setBlogPosts(posts);
      } catch (err) {
        console.error('Error fetching blog posts:', err);
        setError('Failed to load blog posts. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPosts();
  }, []);

  if (loading) {
    return (
      <div className="blog-container">
        <div className="container">
          <div className="loading-spinner">
            <div className="spinner-border">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="blog-container">
        <div className="container">
          <div className="error-message" role="alert">
            {error}
          </div>
        </div>
      </div>
    );
  }

  // Format date helper function
  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    return date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };

  return (

          <div className="blog-container">
      {/* Header Section */}
      <div className="blog-header">
        <div className="container">
          <h1>Growler Guru Blog</h1>
          <p>Discover the latest insights about craft beer distribution and industry trends</p>
        </div>
      </div>

      {/* Blog Grid Section */}
      <div className="container">
        <div className="blog-grid">
          {blogPosts.map(post => (
            <article key={post.id} className="blog-card">
              <Link to={`/blog/${post.slug}`}>
                {post.coverImage && (
                  <div className="blog-card__image">
                    <img 
                      src={post.coverImage}
                      alt={post.title}
                    />
                  </div>
                )}
                
                <div className="blog-card__content">
                  <div className="blog-card__date">
                    {formatDate(post.timestamp)}
                  </div>
                  
                  <h2 className="blog-card__title">
                    {post.title}
                  </h2>
                  
                  <p className="blog-card__excerpt">
                    {post.excerpt}
                  </p>
                  
                  <span className="blog-card__link">
                    Read More
                    <svg 
                      width="20" 
                      height="20" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2"
                      strokeLinecap="round" 
                      strokeLinejoin="round"
                    >
                      <path d="M9 5l7 7-7 7" />
                    </svg>
                  </span>
                </div>
              </Link>
            </article>
          ))}
        </div>
      </div>
    </div>

	  /* static blog
    <div className="blog-container">
      <div className="container mt-5 pt-5">
        <h2 className="text-center mb-4">Growler Guru Blog</h2>
        <div className="row">
          {blogPosts.map(post => (
            <div key={post.id} className="col-md-6 mb-4">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title">
                    <Link to={`/blog/${post.slug}`} className="text-decoration-none">
                      {post.title}
                    </Link>
                  </h5>
                  <p className="card-text text-muted">
                    <small>{new Date(post.timestamp).toLocaleDateString()}</small>
                  </p>
                  <p className="card-text">{post.excerpt}</p>
                  <Link to={`/blog/${post.slug}`} className="btn btn-primary">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    */
  );
};
