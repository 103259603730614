import React from 'react';
import { Link } from 'react-router-dom';

// NOTE: Need to re-enable for future growler db
//import { collection, getDocs, orderBy, query } from 'firebase/firestore';
//import { db } from '../../firebase';

export const GrowlerMap = () => {
  const mapUrl = 'https://maps.app.goo.gl/Nn3Zq5QkRAovLheY8';

  return (
     <div className="blog-container">
      {/* Header Section */}
      <div className="blog-header">
        <div className="container">
          <h1>Growler Guru's Growler Map!</h1>
          <p>Discover the latest insights about craft beer distribution and industry trends</p>
        </div>
      </div>

      {/* Map Section */}
      <div className="container">

          <div style={{
      padding: '1rem',
      borderRadius: '0.5rem',
      backgroundColor: 'white',
      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)'
    }}>
      <div style={{
        position: 'relative',
        width: '100%',
        paddingBottom: '56.25%', // 16:9 aspect ratio
      }}>
        <iframe
          src="https://www.google.com/maps/d/u/0/embed?mid=1ZBWYOoq4NJD4fpYUh2CdTh7ae_fUfC8&ehbc=2E312F&noprof=1"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 0,
            borderRadius: '0.375rem'
          }}
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Growler Map Locations"
        />
      </div>
    </div>
    </div>
  </div>
  );
};
